<template>
    <div v-if="show" class="prompt-overlay bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50">
      <div class="prompt-modal mx-auto w-80 md:w-50 sm:w-80 bg-white rounded-lg p-6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <h2 class="text-xl font-bold">{{ options.title }}</h2>
        <p v-if="options.message" class="mb-4 max-w-full break-words whitespace-normal">{{ options.message }}</p>
        <form>
            <input v-if="options.type === 'input'" v-model="inputValue" :type="options.input === 'password' ? 'password' : 'text'" class="border border-gray-300 rounded-lg p-2 mb-2 w-full" autocomplete="new-password" @keydown.enter.prevent />
        </form>
        <p v-if="error" class="prompt-error text-red-500 mb-2">* {{ options.error }}</p>
        <div class="prompt-buttons flex justify-end">
            <button v-if="options.type !== 'alert'" @click="onCancel" class="mr-2 px-4 py-2 rounded-lg text-white bg-gray-500 hover:bg-gray-600 w-1/2">{{ options.cancelLabel }}</button>
            <button @click="onConfirm" class="px-4 py-2 rounded-lg text-white bg-blue-500 hover:bg-blue-600 w-1/2">{{ options.confirmLabel }}</button>
        </div>          
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Prompt',
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        inputValue: this.options.defaultValue,
        error: '',
      };
    },
    methods: {
      reset() {
        this.inputValue = this.options.defaultValue;
        this.error = '';
      },
      setError(error) {
        this.error = error;
      },
      onConfirm() {
        if (this.options.type === 'input') {
          if (!this.inputValue) {
            this.setError('Please enter a value');
            return;
          }
          if (this.options.onConfirm) {
            this.options.onConfirm(this.inputValue);
          }
          this.reset();
        } else {
          if (this.options.onConfirm) {
            this.options.onConfirm();
          }
        }
      },
      onCancel() {
        if (this.options.onCancel) {
          this.options.onCancel();
        }
        this.reset();
      },
    },
  };
  </script>
  
<template>
  <div class="relative">
    <div @mouseover="showTooltip = true" @mouseout="showTooltip = false">
      <slot></slot>
    </div>
    <div
      v-if="showTooltip"
      class="tooltip"
      :class="[positionClasses]"
      @mouseover="tooltipHovered = true"
      @mouseout="tooltipHovered = false"
    >
      <div class="tooltip-tail"></div>
      <div class="tooltip-content">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Tooltip Text"
    },
    position: {
      type: String,
      default: "top"
    }
  },
  data() {
    return {
      showTooltip: false,
      tooltipHovered: false
    };
  },
  computed: {
    positionClasses() {
      switch (this.position) {
        case "top":
          return "tooltip-top";
        case "bottom":
          return "tooltip-bottom";
        case "left":
          return "tooltip-right";
        case "right":
          return "tooltip-left";
        default:
          return "";
      }
    }
  }
};
</script>

<style>
.tooltip {
  position: absolute;
  z-index: 999; /* Increase the z-index value */
  background-color: black;
  color: white;
  padding: 8px;
  border-radius: 4px;
  width: max-content; /* Set width to max-content */
}

.tooltip-content {
  margin-right: 10px;
}

.tooltip-tail {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.tooltip-top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-top .tooltip-tail {
  top: 100%;
  border-top: 5px solid black;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-bottom .tooltip-tail {
  bottom: 100%;
  border-bottom: 5px solid black;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-right {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.tooltip-left {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

</style>

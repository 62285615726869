const API_BASE_URL = "https://faas-sgp1-18bc02ac.doserverless.co/api/v1/web/fn-18049d16-6770-4e64-8a95-4a6d98f69eb4/default";
const BASIC_AUTH_TOKEN = "YzdiNDM5NGQtNTgyMy00ZmU0LWFlYmUtYjhjOTg3NDA1NjkyOll4VUg2UmJWTE5KQkJzWGZ2WVN1MFNyaEUzQ2NFczk5VEVoWUZCZ3NkTWoxc0NPWmZmMVloRDBQelVKMHNIMWQ=";

export default {
  pageLoading(show) {
    const pageOverlayMarkup = `
      <div class="fixed bottom-0 left-0 m-2 p-2 flex items-center bg-black bg-opacity-80 rounded-lg">
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" style="border-top-color: #ff0000; animation: spin 1s linear infinite;"></div>
        <div class="text-white ml-2" style="padding-left: 5px;">กำลังประมวลผลข้อมูล...</div>
      </div>
    `;  
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `;
    document.head.appendChild(styleElement);
  
    if (show) {
      // Show overlay
      const pageOverlay = document.createElement('div');
      pageOverlay.innerHTML = pageOverlayMarkup.trim();
      document.body.appendChild(pageOverlay);
    } else {
      // Hide overlay after 1 second
      setTimeout(() => {
        const pageOverlay = document.querySelector('.fixed.bottom-0.left-0.m-2.flex.items-center');
        if (pageOverlay) {
          pageOverlay.remove();
        }
      }, 1000);
    }
  },
  async encrypt(plaintext, salt, passphrase) {
    const encoder = new TextEncoder();
    const key = await this.deriveKey(salt, passphrase);
    const iv = crypto.getRandomValues(new Uint8Array(16));
    const algorithm = { name: 'AES-CBC', iv: iv };
    const encrypted = await crypto.subtle.encrypt(algorithm, key, encoder.encode(plaintext));
    const encodedIV = btoa(String.fromCharCode(...iv));
    const encodedCiphertext = btoa(String.fromCharCode(...new Uint8Array(encrypted)));
    return `${encodedIV}:${encodedCiphertext}`;
  },
  async decrypt(ciphertext, salt, passphrase) {
    const [encodedIV, encodedCiphertext] = ciphertext.split(':');
    let iv, ciphertextBytes;
    try {
      iv = new Uint8Array(atob(encodedIV).split('').map(c => c.charCodeAt(0)));
      ciphertextBytes = new Uint8Array(atob(encodedCiphertext).split('').map(c => c.charCodeAt(0)));
    } catch (error) {
      return Promise.reject(new Error('Invalid ciphertext'));
    }
    const key = await this.deriveKey(salt, passphrase);
    const algorithm = { name: 'AES-CBC', iv: iv };
    const decrypted = await crypto.subtle.decrypt(algorithm, key, ciphertextBytes);
    const decoder = new TextDecoder();
    return decoder.decode(decrypted);
  },
  async  deriveKey(salt, passphrase) {
    const encoder = new TextEncoder();
    const keyMaterial = await crypto.subtle.importKey(
      'raw',
      encoder.encode(passphrase),
      { name: 'PBKDF2' },
      false,
      ['deriveBits']
    );
    const key = await crypto.subtle.deriveBits(
      { name: 'PBKDF2', salt: encoder.encode(salt), iterations: 100000, hash: 'SHA-256' },
      keyMaterial,
      256
    );
    return crypto.subtle.importKey('raw', key, 'AES-CBC', false, ['encrypt', 'decrypt']);
  },
  async getNotes() {
    try {
      this.pageLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/all?collection=notes&page=1&perPage=1000&sorting=createdAt|desc`,
        {
          headers: {
            Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      this.pageLoading(false);

      return {
        data: result.result.data,
        pagination: {
          currentPage: result.result.pagination.currentPage,
          hasNext: result.result.pagination.hasNext,
          hasPrev: result.result.pagination.hasPrev,
          totalItems: result.result.pagination.totalItems,
          totalPages: result.result.pagination.totalPages,
        },
        status: result.result.status,
      };
    } catch (err) {
      console.error(err);
      return {
        data: [],
        pagination: {},
        status: 500,
      };
    }
  },
  async getGroups() {
    try {
      this.pageLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/read/?query={"type":"group","sorting":"createAt|desc"}&collection=notes&page=1`,
        {
          headers: {
            Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      this.pageLoading(false);
      return {
        data: result.result.data,
        pagination: {
          currentPage: result.result.pagination.currentPage,
          hasNext: result.result.pagination.hasNext,
          hasPrev: result.result.pagination.hasPrev,
          totalItems: result.result.pagination.totalItems,
          totalPages: result.result.pagination.totalPages,
        },
        status: result.result.status,
      };
    } catch (err) {
      console.error(err);
      return {
        data: [],
        pagination: {},
        status: 500,
      };
    }
  },
  async getGroupNotes(groupId) {
    try {
      this.pageLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/read/?query={"groupID":"${groupId}","sorting":"createAt|desc"}&collection=notes&page=1`,
        {
          headers: {
            Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      this.pageLoading(false);
      return {
        data: result.result.data.result,
        pagination: {
          currentPage: result.result.pagination.currentPage,
          hasNext: result.result.pagination.hasNext,
          hasPrev: result.result.pagination.hasPrev,
          totalItems: result.result.pagination.totalItems,
          totalPages: result.result.pagination.totalPages,
        },
        status: result.result.status,
      };
    } catch (err) {
      console.error(err);
      return {
        data: [],
        pagination: {},
        status: 500,
      };
    }
  },
  async getNote(noteId) {
    try {
        this.pageLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/read/?query={"id":"${noteId}","sorting":"createAt|asc"}&collection=notes&level=0`,
        {
          headers: {
            Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      this.pageLoading(false);
      return {
        id: result.result._id,
        title: result.result.title,
        content: result.result.content,
        isLocked: result.result.isLocked,
        groupID: result.result.groupID,
        password: result.result.password,
        createdAt: new Date(result.result.createdAt),
      };
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async updateNote(noteId, newContent) {
    try {
        this.pageLoading(true);
      const response = await fetch(`${API_BASE_URL}/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
        },
        body: JSON.stringify({
          data: {
            content: newContent,
          },
          collection: "notes",
          id: noteId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      this.pageLoading(false);
      return result;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async changeNoteGroup(noteId, newContent) {
    try {
        this.pageLoading(true);
      const response = await fetch(`${API_BASE_URL}/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
        },
        body: JSON.stringify({
          data: {
            groupID: newContent,
          },
          collection: "notes",
          id: noteId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      this.pageLoading(false);
      return result;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async pinNote(noteId, value) {
    try {
      this.pageLoading(true);
      const response = await fetch(`${API_BASE_URL}/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
        },
        body: JSON.stringify({
          data: {
            isPin: value,
          },
          collection: "notes",
          id: noteId,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      this.pageLoading(false);
      return { status: true, result };
    } catch (err) {
      console.error(err);
      return { status: false, result: null };
    }
  }, 
  async renameNote(noteId, value) {
    try {
      this.pageLoading(true);
      const response = await fetch(`${API_BASE_URL}/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
        },
        body: JSON.stringify({
          data: {
            title: value,
          },
          collection: "notes",
          id: noteId,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      this.pageLoading(false);
      return { status: true, result };
    } catch (err) {
      console.error(err);
      return { status: false, result: null };
    }
  }, 
  async lockNote(noteId, value, password) {
    try {
      this.pageLoading(true);
      let set_password = (value === 'no') ? null : password;
      const response = await fetch(`${API_BASE_URL}/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
        },
        body: JSON.stringify({
          data: {
            isLocked: value,
            password: set_password,
          },
          collection: "notes",
          id: noteId,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      this.pageLoading(false);
      return { status: true, result };
    } catch (err) {
      console.error(err);
      return { status: false, result: null };
    }
  },  
  async addNote(title, content, createdDate, groupID="") {
    const data = {
      title: title,
      content: content,
      createdAt: createdDate,
      groupID: groupID,
      isPin: "no",
      isLocked: "no",
      type: "note",
    };
    this.pageLoading(true);
    const response = await fetch(`${API_BASE_URL}/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
      },
      body: JSON.stringify({
        data: data,
        collection: "notes",
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    this.pageLoading(false);
    console.log("result",result);

    if (groupID) {
        const parentNote = await this.getNote(groupID);

        if (parentNote && parentNote.content) {
            parentNote.content.push(result.result._id);
            await this.updateNote(groupID, parentNote.content);
        }
    }

    return result.result._id;
  },
  async addGroupNote(groupName) {
    const title = groupName;
    const content = [];
    const type = "group";
    const createdDate = new Date();
  
    const data = {
      title: title,
      content: content,
      type: type,
      createdAt: createdDate,
      isPin: "no",
      isLocked: "no",
    };
  
    this.pageLoading(true);
    const response = await fetch(`${API_BASE_URL}/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
      },
      body: JSON.stringify({
        data: data,
        collection: "notes",
      }),
    });
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  
    const result = await response.json();
    const noteId = result.result._id;
  
    this.pageLoading(false);

    return noteId;
  },
  async deleteNote(noteId, group = false) {
    try {
      this.pageLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/delete/?id=${noteId}&collection=notes`,
        {
          method: "GET",
          headers: {
            Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      this.pageLoading(false);
  
      if (group) {
        const parentNote = await this.getNote(group);
  
        if (parentNote && parentNote.content) {
          parentNote.content = parentNote.content.filter(
            (note) => note !== noteId
          );
          await this.updateNote(group, parentNote.content);
        }
      }
  
      return result;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async assignGroup(noteId, new_groupId, old_groupId) {

    console.log(noteId);
    console.log(new_groupId);
    console.log(old_groupId);
    this.pageLoading(true);

    if (old_groupId === null || old_groupId === undefined || old_groupId === "")  {
      // Add Note to new groups
      const parentNew = await this.getNote(new_groupId);
      if (parentNew && parentNew.content) {
        parentNew.content.push(noteId);
        await this.updateNote(new_groupId, parentNew.content);
        console.log("New Group");
      }
    }
    else
    {
      // Add Note to new groups
      const parentNew = await this.getNote(new_groupId);
      if (parentNew && parentNew.content) {
        parentNew.content.push(noteId);
        await this.updateNote(new_groupId, parentNew.content);
        console.log("New Group");
      }
      // Remove Note to old groups
      const parentOld = await this.getNote(old_groupId);
      if (parentOld && parentOld.content) {
        parentOld.content = parentOld.content.filter(
          (note) => note !== noteId
        );
        await this.updateNote(old_groupId, parentOld.content);
        console.log("old Group");
      }
    }
    
    await this.changeNoteGroup(noteId, new_groupId);

    this.pageLoading(false);
    return noteId;
  },
  async leaveGroup(noteId, old_groupId) {

    console.log(noteId);
    console.log(old_groupId);
    this.pageLoading(true);

    const parentOld = await this.getNote(old_groupId);
    if (parentOld && parentOld.content) {
      parentOld.content = parentOld.content.filter(
        (note) => note !== noteId
      );
      await this.updateNote(old_groupId, parentOld.content);
      console.log("old Group");
    }
    await this.changeNoteGroup(noteId, null);
    //this.pageLoading(false);
    return noteId;
  },
  async getTemp() {
    try {
      this.pageLoading(true);
      let temp = localStorage.getItem("temp");
      if (!temp) {
        temp = '';
        localStorage.setItem("temp", temp);
      }
      this.pageLoading(false);
      return {
        id: null,
        title: null,
        content: temp,
        isLocked: null,
        groupID: null,
        password: null,
        createdAt: null,
      };
    } catch (err) {
      console.error(err);
    }
  },
  async updateTemp(content) {
    try {
      this.pageLoading(true);
      localStorage.setItem("temp", content);
      this.pageLoading(false);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
};
